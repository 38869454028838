/* eslint-disable no-unused-vars */
import Vue from "vue";
import router from "@/router";
import axios from "axios";
import AuthService from "./auth-services";
import idb from "@/indexeddb";

let origin = null;
if (window.location.origin == "http://localhost:8080") {
  origin = process.env.VUE_APP_API_BASE_URL;
} else {
  origin = window.location.origin;
}

const API_URL = `${origin}/api/`;

const state = {
  namespaced: true,
  status: {
    loggedIn: false
  },
  user: null
};

(async () => {
  let users = await idb.getUsers();
  if (users.length > 0) {
    state.status.loggedIn = true;
    state.user = users[0];
  }
})();

const getters = {
  isAuthenticated(state) {
    return state.status;
  }
};

const mutations = {
  async loginSuccess(state, user) {
    state.status.loggedIn = true;
    state.user = user;
    localStorage.setItem("token", user.token);
    await idb.saveUser(user);
    Vue.$http = axios.create({
      baseURL: API_URL,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${user.token}`
      }
    });
    router.push("/mosaico");
  },
  loginFailure(state) {
    state.status.loggedIn = false;
    state.user = null;
  },
  async logout(state, data) {
    state.status.loggedIn = false;
    await idb.deleteUser(state.user);
    localStorage.removeItem("token");
    state.user = null;
    router.push("/login");
  },
  async registerSuccess(state, user) {
    state.status.loggedIn = true;
    state.user = user;
    localStorage.setItem("token", user.token);
    await idb.saveUser(user);
    Vue.$http = axios.create({
      baseURL: API_URL,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${user.token}`
      }
    });
    router.push("/");
  },
  registerFailure(state) {
    state.status.loggedIn = false;
  },
  tokenSuccess(state, userToken) {
    state.userToken = userToken;
  },
  tokenFailure(state) {
    state.userToken = false;
  },
  patchSuccess(state, user) {
    let userOld = JSON.parse(localStorage.getItem("user"));
    userOld.email = user.email;
    userOld.username = user.username;
    localStorage.setItem("user", JSON.stringify(userOld));
  }
};

const actions = {
  login({ commit }, user) {
    return AuthService.login(user).then(
      user => {
        commit("loginSuccess", user);
        return Promise.resolve(user);
      },
      error => {
        commit("loginFailure");
        return Promise.reject(error);
      }
    );
  },
  logout({ commit }) {
    AuthService.logout().then(data => {
      commit("logout", data);
    });
  },
  register({ commit }, user) {
    return AuthService.register(user).then(
      user => {
        commit("registerSuccess", user);
        return Promise.resolve(user);
      },
      error => {
        commit("registerFailure");
        return Promise.reject(error);
      }
    );
  },
  getProfile(context, user) {
    return AuthService.getProfile(user).then(
      user => {
        return Promise.resolve(user);
      },
      error => {
        return Promise.reject(error);
      }
    );
  },
  patchProfile({ commit }, user) {
    return AuthService.patchProfile(user).then(
      user => {
        commit("patchSuccess", user);
        return Promise.resolve(user);
      },
      error => {
        return Promise.reject(error);
      }
    );
  },
  patchProfileImage({ commit }, user) {
    return AuthService.patchProfileImage(user).then(
      user => {
        commit("patchSuccess", user);
        return Promise.resolve(user);
      },
      error => {
        return Promise.reject(error);
      }
    );
  }
};

const auth = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default auth;
