export default [
  {
    path: "/login",
    name: "login",
    component: () => import("./Login"),
    meta: {
      auth: false,
      alias: "Login"
    }
  }
];
