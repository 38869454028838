import DashboardLayout from "../layout/dashboard/DashboardLayout.vue";
import AuthLayout from "../modules/auth/layout/AuthLayout.vue";
import Site from "../layout/site/Site.vue";

//Routes pages
import { routes as covid } from "../pages/covid";
import { routes as health_unit } from "../pages/health_unit";
import { routes as sih } from "../pages/sih";
import { routes as sim } from "../pages/sim";

import { routes as maps } from "../pages/maps";
import { routes as datasets } from "../pages/datasets";
import { routes as users } from "../pages/users";
import { routes as not_authorized } from "../pages/not_authorized";
import { routes as home } from "../pages/home";

//Router auth
import { routes as auth } from "../modules/auth";

const routes = [
  {
    path: "/",
    component: Site,
    redirect: "home",
    children: [...home]
  },
  {
    path: "/mosaico/",
    component: DashboardLayout,
    redirect: "/mosaico/health_unit",
    children: [
      ...covid,
      ...health_unit,
      ...datasets,
      ...maps,
      ...not_authorized,
      ...users,
      ...sih,
      ...sim
    ]
  },
  {
    path: "/",
    component: AuthLayout,
    name: "Authentication",
    children: auth
  }
];

export default routes;
