import auth from "../modules/auth/store/auth-module";

export default async (to, from, next) => {
  document.title = `S3 BioTech`;

  const loggedIn = auth.state.status.loggedIn;
  const user = auth.state.user;

  if (to.meta.auth) {
    if (loggedIn) {
      if (to.meta.role == "guest") {
        next();
      } else {
        let permission = false;
        user.roles.forEach(role => {
          if (role.name == to.meta.role) {
            permission = true;
          }
        });
        if (permission) {
          next();
        } else {
          next("/not_authorized");
        }
      }
    } else {
      next("/login");
    }
  } else {
    next();
  }
};
