export default [
  {
    path: "/reset-password/:token/:email",
    name: "Resetar Password",
    component: () => import("./ResetPassword"),
    meta: {
      auth: false,
      alias: "Resetar Password"
    }
  },
  {
    path: "/forgot-password",
    name: "Criar Password",
    component: () => import("./ForgotPassword"),
    meta: {
      auth: false,
      alias: "Resetar Password"
    }
  }
];
