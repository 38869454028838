var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:[
    { 'nav-open': _vm.$sidebar.showSidebar },
    { rtl: _vm.$route.meta.rtlActive }
  ]},[_c('notifications'),_c('side-bar',{attrs:{"active-color":_vm.sidebarBackground,"background-image":_vm.sidebarBackgroundImage,"data-background-color":_vm.sidebarBackgroundColor}},[_c('mobile-menu'),_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Análise SIM',
          icon_fa: 'fas fa-skull-crossbones',
          path: '/mosaico/sim'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Análise SIH',
          icon: 'health_and_safety',
          path: '/mosaico/sih'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Hospitalizações',
          icon: 'local_hospital',
          path: '/mosaico/health_unit'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Covid-19 Brasil',
          icon: 'coronavirus',
          path: '/mosaico/covid/chart_country/brasilio/covid'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Covid-19 Estado',
          icon: 'coronavirus',
          path: '/mosaico/covid/chart_state/brasilio/covid'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Covid-19 Piauí',
          icon: 'coronavirus',
          path: '/mosaico/covid/chart_state_sesapi/brasilio/covid'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Covid-19',
          icon: 'coronavirus',
          path: '/mosaico/covid'
        }}}),(_vm.isAdmin)?_c('sidebar-item',{attrs:{"link":{
          name: 'Data Sets',
          icon: 'backup',
          path: '/mosaico/datasets'
        }}}):_vm._e()],1)],2),_c('div',{staticClass:"main-panel"},[_c('top-navbar',{attrs:{"title":_vm.title}}),_c('div',{staticClass:"content md-mt-0 md-pt-0"},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view',{on:{"welcomeMessage":_vm.setMessage}})],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }