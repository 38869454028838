import Vue from "vue";
import moment from "moment-mini";

Vue.filter("formatDate", value => {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY");
  }
});
Vue.filter("formatDateTime", value => {
  if (value) {
    return moment(value).format("DD/MM/YYYY HH:mm");
  }
});
Vue.filter("truncate", (value, limit) => {
  if (value.length > limit) {
    value = value.substring(0, limit - 3) + "...";
  }
  return value;
});
Vue.filter("strippedHtml", value => {
  let regex = /(<([^>]+)>)/gi;
  return value.replace(regex, "");
});
Vue.filter("capitalize", value => {
  if (!value) {
    return "";
  } else {
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
});

Vue.filter("locateNumber", value => {
  if (!value) {
    return "";
  } else {
    return value.toLocaleString("pt-BR");
  }
});

Vue.prototype.$colorCases = {
  cases_5: "#ffffb2",
  cases_10: "#fed976",
  cases_20: "#feb24c",
  cases_30: "#fc4e2a",
  cases_40: "#e31a1c;",
  cases_50: "#800026"
};

Vue.prototype.$colorDeaths = {
  cases_5: "#f7fcfd",
  cases_10: "#bfd3e6",
  cases_20: "#8c96c6",
  cases_30: "#8c6bb1",
  cases_40: "#88419d;",
  cases_50: "#4d004b"
};

Vue.prototype.$colorStage = {
  green: "#006d2c",
  yellow: "#fbbc04",
  red: "#b30000",
  purple: "#810f7c"
};

Vue.prototype.$deepPurple = [
  "#ede7f6",
  "#d1c4e9",
  "#b39ddb",
  "#9575cd",
  "#7e57c2",
  "#673ab7",
  "#5e35b1",
  "#512da8",
  "#4527a0",
  "#311b92",
  "#b388ff",
  "#7c4dff",
  "#651fff",
  "#6200ea"
];

Vue.prototype.$sunSet = [
  "#f3e79b",
  "#fac484",
  "#f8a07e",
  "#eb7f86",
  "#ce6693",
  "#a059a0",
  "#5c53a5"
];
