//import DropDown from "./components/Dropdown.vue";
import Parallax from "./components/Parallax.vue";

import { ValidationProvider } from "vee-validate";
import { ValidationObserver } from "vee-validate";
import { required, email, confirmed, regex } from "vee-validate/dist/rules";
import { localize, extend } from "vee-validate";
//import { VPopover } from "v-tooltip";

extend("email", email);
extend("required", required);
extend("confirmed", confirmed);
extend("regex", regex);

import pt_BR from "vee-validate/dist/locale/pt_BR";

localize("pt_BR", pt_BR);

import {
  CollapseTransition,
  SlideYDownTransition,
  FadeTransition,
  ZoomCenterTransition
} from "../node_modules/vue2-transitions";
/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install(Vue) {
    //Vue.component("drop-down", DropDown);
    Vue.component("ValidationProvider", ValidationProvider);
    Vue.component("ValidationObserver", ValidationObserver);
    Vue.component("CollapseTransition", CollapseTransition);
    Vue.component("SlideYDownTransition", SlideYDownTransition);
    Vue.component("FadeTransition", FadeTransition);
    Vue.component("ZoomCenterTransition", ZoomCenterTransition);
    Vue.component(Parallax.name, Parallax);
    //Vue.component("v-popover", VPopover);
  }
};

export default GlobalComponents;
