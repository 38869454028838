export default [
  {
    path: "sim",
    name: "sim",
    component: () => import("./Sim"),
    meta: {
      auth: true,
      role: "staff"
    }
  },
  {
    path:
      "sim/details/:source/:type/:id/:location_type?/:location_id?/:scope_location?",
    name: "sim_details",
    component: () => import("./SimDetails"),
    meta: {
      auth: true,
      role: "staff"
    }
  },
  {
    path: "sim/map/:source/:type/:id/:location_type?/:location_id?",
    name: "sim_map",
    component: () => import("./SimMap"),
    meta: {
      auth: true,
      role: "staff"
    }
  }
];
