export default [
  {
    path: "users",
    name: "Usuários",
    component: () => import("./ListUser"),
    meta: {
      auth: true,
      role: "admin"
    }
  }
];
