import Vue from "vue";
import "./globalFilters";
import App from "./App.vue";
import DashboardPlugin from "./material-dashboard";

import SideBar from "./components/SidebarPlugin";
// Global
import VueTheMask from "vue-the-mask";
import VueAxios from "vue-axios";
import axios from "@/axios";
// router & store setup
import router from "./router";
import store from "./store";

import VueMaterial from "vue-material";
import Croppa from "vue-croppa";

(async () => {
  let http = await axios.create();
  Vue.use(Croppa);
  Vue.use(VueAxios, await http);

  Vue.use(VueTheMask);
  Vue.use(VueMaterial);
  Vue.use(DashboardPlugin);
  Vue.use(SideBar);

  Vue.material.locale.shortDays = [
    "Dom",
    "Seg",
    "Ter",
    "Qua",
    "Qui",
    "Sex",
    "Sab"
  ];

  Vue.material.locale.days = [
    "Domingo",
    "Segunda-Feira",
    "Terça-Feira",
    "Quarta-Feira",
    "Quinta-Feira",
    "Sexta-Feira",
    "Sábado"
  ];

  Vue.material.locale.shorterDays = ["D", "S", "T", "Q", "Q", "S", "S"];

  Vue.material.locale.months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro"
  ];

  Vue.material.locale.shortMonths = [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez"
  ];

  Vue.material.locale.shorterMonths = [
    "J",
    "F",
    "M",
    "A",
    "M",
    "Jun",
    "Jul",
    "A",
    "Se",
    "O",
    "N",
    "D"
  ];

  /* eslint-disable no-new */
  const app = new Vue({
    router: router,
    store: store,
    el: "#app",
    render: h => h(App)
  });

  store.$app = app;
})();
