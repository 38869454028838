export default [
  {
    path: "health_unit",
    name: "health_unit",
    component: () => import("./HealthUnit"),
    meta: {
      auth: true,
      role: "guest"
    }
  },
  {
    path: "health_unit/chart/:source/:datasetName/:type/:filter",
    name: "health_unit_chart",
    component: () => import("./HealthUnitChart"),
    meta: {
      auth: true,
      role: "guest"
    }
  },
  {
    path: "health_unit/maps/:source/:datasetName/:type",
    name: "health_unit_map",
    component: () => import("./HealthUnitMap"),
    meta: {
      auth: true,
      role: "guest"
    }
  }
];
