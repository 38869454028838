export default [
  {
    path: "home",
    name: "home",
    component: () => import("./Home"),
    meta: {
      auth: false
    }
  }
];
