<template>
  <div
    class="wrapper"
    :class="[
      { 'nav-open': $sidebar.showSidebar },
      { rtl: $route.meta.rtlActive }
    ]"
  >
    <notifications></notifications>
    <side-bar
      :active-color="sidebarBackground"
      :background-image="sidebarBackgroundImage"
      :data-background-color="sidebarBackgroundColor"
    >
      <mobile-menu></mobile-menu>
      <template slot="links">
        <sidebar-item
          :link="{
            name: 'Análise SIM',
            icon_fa: 'fas fa-skull-crossbones',
            path: '/mosaico/sim'
          }"
        />
        <sidebar-item
          :link="{
            name: 'Análise SIH',
            icon: 'health_and_safety',
            path: '/mosaico/sih'
          }"
        />
        <sidebar-item
          :link="{
            name: 'Hospitalizações',
            icon: 'local_hospital',
            path: '/mosaico/health_unit'
          }"
        />
        <sidebar-item
          :link="{
            name: 'Covid-19 Brasil',
            icon: 'coronavirus',
            path: '/mosaico/covid/chart_country/brasilio/covid'
          }"
        />
        <sidebar-item
          :link="{
            name: 'Covid-19 Estado',
            icon: 'coronavirus',
            path: '/mosaico/covid/chart_state/brasilio/covid'
          }"
        />
        <sidebar-item
          :link="{
            name: 'Covid-19 Piauí',
            icon: 'coronavirus',
            path: '/mosaico/covid/chart_state_sesapi/brasilio/covid'
          }"
        />
        <sidebar-item
          :link="{
            name: 'Covid-19',
            icon: 'coronavirus',
            path: '/mosaico/covid'
          }"
        />
        <sidebar-item
          v-if="isAdmin"
          :link="{
            name: 'Data Sets',
            icon: 'backup',
            path: '/mosaico/datasets'
          }"
        />
      </template>
    </side-bar>

    <div class="main-panel">
      <top-navbar :title="title"></top-navbar>
      <div class="content md-mt-0 md-pt-0">
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view @welcomeMessage="setMessage"></router-view>
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
    document.getElementsByClassName(className)[0].scrollTop = 0;
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

function reinitScrollbar() {
  let docClasses = document.body.classList;
  let isWindows = navigator.platform.startsWith("Win");
  if (isWindows) {
    // if we are on windows OS we activate the perfectScrollbar function
    initScrollbar("sidebar");
    initScrollbar("sidebar-wrapper");
    initScrollbar("main-panel");

    docClasses.add("perfect-scrollbar-on");
  } else {
    docClasses.add("perfect-scrollbar-off");
  }
}

import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import MobileMenu from "./Extra/MobileMenu.vue";

export default {
  components: {
    TopNavbar,
    ContentFooter,
    MobileMenu
  },
  props: {
    image: {
      type: String,
      default: require("@/assets/img/biotech.svg")
    }
  },
  data() {
    return {
      title: "",
      sidebarBackgroundColor: "black",
      sidebarBackground: "danger",
      sidebarBackgroundImage:
        process.env.VUE_APP_APP_BASE_URL + "/img/sidebar.jpg",
      sidebarMini: true,
      sidebarImg: true,
      crossbones: '<i class="fas fa-skull-crossbones"></i>'
    };
  },
  methods: {
    setMessage(data) {
      this.title = data.title;
    },
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    }
  },
  updated() {
    reinitScrollbar();
  },
  mounted() {
    reinitScrollbar();
  },
  computed: {
    // a computed getter
    isAdmin() {
      let permission = false;
      this.$store.state.auth.user.roles.forEach(role => {
        if (role.name == "admin") {
          permission = true;
        }
      });
      return permission;
    }
  },
  watch: {
    sidebarMini() {
      this.minimizeSidebar();
    }
  }
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}
.main-panel .zoomIn {
  animation-name: zoomIn95;
}
@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}
.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
