export default [
  {
    path: "covid",
    name: "covid",
    component: () => import("./Covid"),
    meta: {
      auth: true,
      role: "guest"
    }
  },
  {
    path: "covid/chart/:source/:datasetName/:type/:filter",
    name: "datasetschart",
    component: () => import("./CovidChart"),
    meta: {
      auth: true,
      role: "guest"
    }
  },
  {
    path: "covid/chart_country/:source/:datasetName",
    name: "datasetschart_country",
    component: () => import("./CovidChartCountry"),
    meta: {
      auth: true,
      role: "guest"
    }
  },
  {
    path: "covid/chart_state/:source/:datasetName",
    name: "datasetschart_state",
    component: () => import("./CovidChartState"),
    meta: {
      auth: true,
      role: "guest"
    }
  },
  {
    path: "covid/chart_state_sesapi/:source/:datasetName",
    name: "datasetschart_state_sesapi;",
    component: () => import("./CovidChartStateSesapi"),
    meta: {
      auth: true,
      role: "guest"
    }
  },
  {
    path: "covid/maps/:source/:datasetName/:type",
    name: "datasetsmap",
    component: () => import("./CovidMap"),
    meta: {
      auth: true,
      role: "guest"
    }
  }
];
