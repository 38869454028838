export default [
  {
    path: "maps",
    name: "maps",
    component: () => import("./Maps"),
    meta: {
      auth: true,
      role: "staff"
    }
  },
  {
    path: "maps/map/:title",
    name: "map",
    component: () => import("./Map"),
    meta: {
      auth: true,
      role: "staff"
    }
  }
];
