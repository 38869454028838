import Vue from "vue";
let origin = null;
import axios from "axios";

if (window.location.origin == "http://localhost:8080") {
  origin = process.env.VUE_APP_API_BASE_URL;
} else {
  origin = window.location.origin;
}

const API_URL = `${origin}/api/`;

class AuthService {
  async login(user) {
    let result = await Vue.$http.post("v1/login", user);
    return result.data;
  }

  async logout() {
    let result = Vue.$http.delete("v1/logout").then(async response => {
      if (response.data) {
        if (response.data.status) {
          Vue.prototype.$http = axios.create({
            baseURL: API_URL
          });
          return true;
        }
      }
    });
    return result;
  }

  async register(user) {
    let result = await Vue.$http.post("v1/register", user);
    return result.data;
  }

  async getProfile(user) {
    return Vue.$http.get(`users/${user.id}/`);
  }

  async patchProfile(user) {
    return Vue.$http.patch(`users/${user.id}/`, {
      username: user.username,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      profile: {
        description: user.profile.description,
        description_full: user.profile.description_full,
        lattesPath: user.profile.lattesPath,
        orcidPath: user.profile.orcidPath
      }
    });
  }

  async patchProfileImage(user) {
    let data = new FormData();
    data.append("profile.imagePath", user.pathImages, "filename.jpg");
    return Vue.$http.patch(`users/${user.id}/`, data, {
      headers: {
        "Content-Type": `multipart/form-data; boundary=${data._boundary}`
      },
      timeout: 30000
    });
  }
}

export default new AuthService();
