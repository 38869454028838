export default [
  {
    path: "/register",
    name: "register",
    component: () => import("./Register"),
    meta: {
      auth: false,
      alias: "Cadastro"
    }
  }
];
