const state = {
  location_type: null,
  location_id: null,
  filter_cids: null,
  filter_chapters_cids: null,
  filter_groups_cids: null
};

const mutations = {
  setLocation(state, payload) {
    state.location_type = payload.location_type;
    state.location_id = payload.location_id;
  },
  setCids(state, payload) {
    state.filter_cids = payload.filter_cids;
  },
  setChaptersCids(state, payload) {
    state.filter_chapters_cids = payload.filter_chapters_cids;
  },
  setGroupsCids(state, payload) {
    state.filter_groups_cids = payload.filter_groups_cids;
  }
};

const actions = {};

const getters = {};

const aih = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default aih;
