import axios from "axios";
import idb from "@/indexeddb";
import router from "@/router";

export default {
  async create() {
    let origin = null;
    let user = null;
    await (async () => {
      let users = await idb.getUsers();
      if (users.length > 0) {
        user = users[0];
      }
    })();

    if (window.location.origin == "http://localhost:8080") {
      origin = process.env.VUE_APP_API_BASE_URL;
    } else {
      origin = window.location.origin;
    }

    const API_URL = `${origin}/api/`;

    let http = axios.create({
      baseURL: API_URL,
      withCredentials: true,
      mode: "no-cors"
    });

    if (user) {
      http = await axios.create({
        baseURL: API_URL,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${await user.token}`,
          withCredentials: true,
          mode: "no-cors"
        }
      });

      http.interceptors.response.use(
        response => {
          return response;
        },
        async error => {
          if (error.response) {
            if (error.response.status === 401) {
              await idb.deleteUser(user);
              http = axios.create({
                baseURL: API_URL
              });
              localStorage.removeItem("user");

              router.push({ path: "/login" });
            }
          }

          return Promise.reject(error);
        }
      );
    }

    http.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
    http.defaults.headers.common["Access-Control-Allow-Methods"] = "*";

    return await http;
  }
};
