export default [
  {
    path: "datasets",
    name: "datasets",
    component: () => import("./DataSets"),
    meta: {
      auth: true,
      role: "staff"
    }
  },
  {
    path: "datasets/list/:source/:type",
    name: "datasetsList;",
    component: () => import("./DataSetList"),
    meta: {
      auth: true,
      role: "staff"
    }
  },
  {
    path: "datasets/edit/:source/:type/:year?",
    name: "datasetsEdit",
    component: () => import("./DataSetEdit"),
    meta: {
      auth: true,
      role: "staff"
    }
  },
  {
    path: "datasets/calendar/:source/:type",
    name: "datasetsCalendar",
    component: () => import("./DataSetCalendar"),
    meta: {
      auth: true,
      role: "staff"
    }
  }
];
