<template>
  <div id="material-site">
    <md-toolbar
      id="toolbar"
      md-elevation="0"
      class="md-transparent-blue md-elevation-4 md-absolute"
      :class="extraNavClasses"
      :color-on-scroll="colorOnScroll"
    >
      <div class="md-toolbar-row md-collapse-lateral">
        <div class="md-toolbar-section-start">
          <img :src="logoMini" alt="logo-mini" class="responsive logo-mini" />
          <h3 class="md-title">| BioTech</h3>
        </div>
        <div class="md-toolbar-section-end">
          <md-button
            class="md-just-icon md-simple md-toolbar-toggle"
            :class="{ toggled: toggledClass }"
            @click="toggleNavbarMobile()"
          >
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </md-button>

          <div class="md-collapse">
            <div class="md-collapse-wrapper">
              <mobile-menu nav-mobile-section-start="false">
                <!-- Here you can add your items from the section-start of your toolbar -->
              </mobile-menu>
              <md-list>
                <md-list-item>
                  <router-link to="/mosaico/">
                    <i class="material-icons">bar_chart</i>
                    <p>Mosaico DashBoard</p>
                  </router-link>
                </md-list-item>
              </md-list>
            </div>
          </div>
        </div>
      </div>
    </md-toolbar>
    <div class="wrapper">
      <parallax class="page-header header-filter" :style="headerStyle">
        <div class="md-layout">
          <div class="md-layout-item">
            <div class="image-wrapper">
              <div class="brand">
                <div class="md-layout md-alignment-center-center">
                  <div class="md-layout-item md-size-70 md-xsmall-size-100">
                    <div class="md-layout md-alignment-center-center">
                      <div
                        class="md-layout-item md-size-20 md-xsmall-size-100 div-logo"
                      >
                        <img :src="logo" alt="logo" />
                      </div>
                      <div
                        class="md-layout-item md-size-50 md-xsmall-size-100 div-logo"
                      >
                        <h1>
                          <span class="md-xsmall-hide md-small-hide">|</span>
                          BioTech
                        </h1>
                      </div>
                    </div>
                  </div>
                  <div class="md-layout-item md-size-100">
                    <h3>Ajudando você a organizar seus DADOS.</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </parallax>
      <zoom-center-transition :duration="pageTransitionDuration" mode="out-in">
        <router-view></router-view>
      </zoom-center-transition>
      <footer
        class="footer"
        :class="{ [`footer-${typeFooter}`]: typeFooter }"
        :data-background-color="backgroundColor"
      >
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item">
              <md-list class="md-double-line">
                <md-divider></md-divider>
                <md-subheader>Endereço</md-subheader>
                <md-list-item>
                  <md-icon class="md-primary">home</md-icon>
                  <div class="md-list-item-text">
                    <span>
                      <address>Rua Nilo Soares da Silva, 1039 sala s3b</address>
                    </span>
                    <span>
                      <address>CEP: 64.049-778 - Ininga - Teresina-PI</address>
                    </span>
                  </div>
                </md-list-item>
              </md-list>
            </div>
            <div class="md-layout-item">
              <md-list class="md-double-line">
                <md-divider></md-divider>
                <md-subheader>Contatos</md-subheader>
                <md-list-item>
                  <md-icon class="md-primary">email</md-icon>
                  <div class="md-list-item-text">
                    <span>
                      <a href="mailto:s3biotech.contato@gmail.com">
                        s3biotech.contato@gmail.com
                      </a>
                    </span>

                    <span>
                      <a href="mailto:jessicamachado.je@gmail.com">
                        jessicamachado.je@gmail.com
                      </a>
                    </span>
                    <span>(86) 98832-7222</span>
                    <span>Jessica Elane Vieira Machado</span>
                  </div>
                  <md-icon class="md-primary">phone</md-icon>
                </md-list-item>
              </md-list>
            </div>
            <div class="md-layout-item">
              <md-list class="md-double-line">
                <md-divider></md-divider>
                <md-subheader>Todos os direitos reservados</md-subheader>
                <md-list-item>
                  <md-icon class="md-primary">bar_chart</md-icon>
                  <div class="md-list-item-text">
                    <span> {{ year }}, Desenvolvido por S3BioTech </span>
                  </div>
                </md-list-item>
              </md-list>
            </div>
            <div class="md-layout-item">
              <md-list class="md-double-line">
                <md-divider></md-divider>
                <md-subheader>Parcerias:</md-subheader>
                <md-list-item>
                  <span>
                    <img
                      :src="logoCentelha"
                      alt="logo-centelha-pi"
                      class="responsive"
                    />
                    <img :src="logoCiaten" alt="logo-ciaten" />
                  </span>
                </md-list-item>
              </md-list>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import MaterialSite from "./plugins/material-site";
import MobileMenu from "./MobileMenu";

Vue.config.productionTip = false;
Vue.use(MaterialSite);

const NavbarStore = {
  showNavbar: false
};

let resizeTimeout;
function resizeThrottler(actualResizeHandler) {
  // ignore resize events as long as an actualResizeHandler execution is in the queue

  if (!resizeTimeout) {
    resizeTimeout = setTimeout(() => {
      resizeTimeout = null;

      actualResizeHandler();

      // The actualResizeHandler will execute at a rate of 15fps
    }, 66);
  }
}

export default {
  data() {
    return {
      NavbarStore,
      pageTransitionDuration: 300,
      extraNavClasses: "",
      toggledClass: false,
      year: new Date().getFullYear()
    };
  },
  props: {
    backgroundColor: { type: String, default: "black" },
    typeFooter: String,
    logoMini: { type: String, default: require("@/assets/img/logo-60x60.png") },
    image: { type: String, default: require("@/assets/img/background.jpg") },
    logo: { type: String, default: require("@/assets/img/logo-192x192.png") },
    logoCiaten: {
      type: String,
      default: require("@/assets/img/logo-ciaten.png")
    },
    logoCentelha: {
      type: String,
      default: require("@/assets/img/logo-centelha-pi.png")
    },
    type: {
      type: String,
      default: "white",
      validator(value) {
        return [
          "white",
          "default",
          "primary",
          "danger",
          "success",
          "warning",
          "info"
        ].includes(value);
      }
    },
    colorOnScroll: { type: Number, default: 400 }
  },
  components: {
    MobileMenu
  },
  methods: {
    bodyClick() {
      let bodyClick = document.getElementById("bodyClick");

      if (bodyClick === null) {
        let body = document.querySelector("body");
        let elem = document.createElement("div");
        elem.setAttribute("id", "bodyClick");
        body.appendChild(elem);

        let bodyClick = document.getElementById("bodyClick");
        bodyClick.addEventListener("click", this.toggleNavbarMobile);
      } else {
        bodyClick.remove();
      }
    },
    toggleNavbarMobile() {
      this.NavbarStore.showNavbar = !this.NavbarStore.showNavbar;
      this.toggledClass = !this.toggledClass;
      this.bodyClick();
    },
    handleScroll() {
      let scrollValue =
        document.body.scrollTop || document.documentElement.scrollTop;
      let navbarColor = document.getElementById("toolbar");
      this.currentScrollValue = scrollValue;
      if (this.colorOnScroll > 0 && scrollValue > this.colorOnScroll) {
        this.extraNavClasses = `md-${this.type}`;
        navbarColor.classList.remove("md-transparent-blue");
      } else {
        if (this.extraNavClasses) {
          this.extraNavClasses = "";
          navbarColor.classList.add("md-transparent-blue");
        }
      }
    },
    scrollListener() {
      resizeThrottler(this.handleScroll);
    },
    scrollToElement() {
      let element_id = document.getElementById("downloadSection");
      if (element_id) {
        element_id.scrollIntoView({ block: "end", behavior: "smooth" });
      }
    }
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.image})`
      };
    },
    signupImage() {
      return {
        backgroundImage: `url(${this.signup})`
      };
    }
  },
  mounted() {
    document.addEventListener("scroll", this.scrollListener);
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.scrollListener);
  }
};
</script>
<style lang="scss" scoped>
.md-transparent-blue {
  background-color: rgba(33, 150, 243, 0.2) !important;
}
.logo-mini {
  max-width: 30px;
  margin-right: 5px;
}

.section-download {
  .md-button + .md-button {
    margin-left: 5px;
  }
}

@media all and (min-width: 991px) {
  .btn-container {
    display: flex;
  }
}
.logo {
  max-width: 160px;
}

.div-logo {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>
