import Vue from "vue";
import Vuex from "vuex";

import auth from "../modules/auth/store/auth-module";
import alerts from "./modules/alerts-module";
import profile from "./modules/profile-module";
import users from "./modules/users-module";
import aih from "./modules/aih-module";
import sim from "./modules/sim-module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    alerts,
    profile,
    users,
    aih,
    sim
  }
});
