export default [
  {
    path: "sih",
    name: "sih",
    component: () => import("./Sih"),
    meta: {
      auth: true,
      role: "staff"
    }
  },
  {
    path: "sih/details/:source/:type/:id/:location_type?/:location_id?",
    name: "sih_details",
    component: () => import("./SihDetails"),
    meta: {
      auth: true,
      role: "staff"
    }
  },
  {
    path:
      "sih/rejected/details/:source/:type/:id/:location_type?/:location_id?",
    name: "sih_rejected_details",
    component: () => import("./SihRejectedDetails"),
    meta: {
      auth: true,
      role: "staff"
    }
  },
  {
    path: "sih/map/:source/:type/:id/:location_type?/:location_id?",
    name: "sih_map",
    component: () => import("./SihMap"),
    meta: {
      auth: true,
      role: "staff"
    }
  }
];
