export default [
  {
    path: "not_authorized",
    name: "Não autorizado",
    component: () => import("./NotAuthorized"),
    meta: {
      auth: true,
      role: "guest"
    }
  }
];
